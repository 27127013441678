require('es6-promise').polyfill();
require('isomorphic-fetch');

const config = require('./config.js');

const createCart = () =>
  fetch(
    config.apiurl + '/cart',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => result.cart);

const getCart = (id) =>
  fetch(
    config.apiurl + '/cart/' + id,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => result ? result.cart : null);

const updateCart = (id, properties) =>
  fetch(
    config.apiurl + '/cart/' + id,
    {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(properties)
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => {
    return result.cart
  });

const addToCart = (id, product_id, quantity, amount, message) =>
  fetch(
    config.apiurl + '/cart/' + id + '/items',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ product_id: product_id, quantity: quantity, amount: amount, message: message })
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => {
    return result.cart
  });

const deleteFromCart = (id, item_id) =>
  fetch(
    config.apiurl + '/cart/' + id + '/items/' + item_id,
    {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => {
    return result.cart
  });

const initiateOrder = (id, token) =>
  fetch(
    config.apiurl + '/cart/' + id + '/order/',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: token })
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => {
    return result
  });

module.exports = {
  createCart,
  getCart,
  updateCart,
  addToCart,
  deleteFromCart,
  initiateOrder
}
