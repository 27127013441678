// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-blogtag-js": () => import("./../src/templates/blogtag.js" /* webpackChunkName: "component---src-templates-blogtag-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadeaubon-js": () => import("./../src/pages/cadeaubon.js" /* webpackChunkName: "component---src-pages-cadeaubon-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-delivery-js": () => import("./../src/pages/checkout/delivery.js" /* webpackChunkName: "component---src-pages-checkout-delivery-js" */),
  "component---src-pages-checkout-information-js": () => import("./../src/pages/checkout/information.js" /* webpackChunkName: "component---src-pages-checkout-information-js" */),
  "component---src-pages-checkout-overview-js": () => import("./../src/pages/checkout/overview.js" /* webpackChunkName: "component---src-pages-checkout-overview-js" */),
  "component---src-pages-checkout-payment-js": () => import("./../src/pages/checkout/payment.js" /* webpackChunkName: "component---src-pages-checkout-payment-js" */),
  "component---src-pages-nieuws-js": () => import("./../src/pages/nieuws.js" /* webpackChunkName: "component---src-pages-nieuws-js" */),
  "component---src-pages-order-cancel-js": () => import("./../src/pages/order/cancel.js" /* webpackChunkName: "component---src-pages-order-cancel-js" */),
  "component---src-pages-order-thanks-js": () => import("./../src/pages/order/thanks.js" /* webpackChunkName: "component---src-pages-order-thanks-js" */)
}

