const config = {};
config.apiurl = 'https://www.nanini.be/api';
config.site = {};
config.site.title = 'Nanini';
config.site.options = {};
config.site.options.eshop = true;
config.site.options.vouchers = true;
config.cms = {};
config.cms.baseUrl = 'content.nanini.be';
config.cms.protocol = 'https';
config.stripe = {};
config.stripe.key = 'pk_live_pMZ1n0FDiRwHtCgyGvGke9NE00cVIM7pB2';
module.exports = config;
