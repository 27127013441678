require('es6-promise').polyfill();
require('isomorphic-fetch');

const config = require('./config.js');

const getTransaction = (id) =>
  fetch(
    config.apiurl + '/transaction/' + id,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })
  .then((result) => result.order)

const getOrder = (id) =>
  fetch(
    config.apiurl + '/cart/' + id + '/order',
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )
  .then((response) => {
    if (response.status >= 400) {
      // @TODO throw new Error("Bad response from server")
      return null
    }
    return response.json()
  })

module.exports = {
  getOrder,
  getTransaction,
}
