import React, { Component } from 'react'
import CartContext from './CartContext'

import { getCart, createCart, updateCart, addToCart, deleteFromCart, initiateOrder } from '../../lib/api/cart';
import { getTransaction } from '../../lib/api/order'

const SAME_ADDRESS = 'use_same_address'
const DIFF_ADDRESS = 'use_diff_address'
const PICKUP_IN_STORE = 'pickup_in_store'

class CartProvider extends Component {
  constructor(props) {
    super(props)

    this.get = (id) => {
      return getCart(id)
        .then(cart => cart)
    }

    this.set = (name, value) => {
      let { cart } = this.state
      cart[name] = value
      this.setState({ cart })
    }

    this.create = () => {
      return createCart()
        .then((cart) => this.transformCart(cart))
        .then((cart) => {
          localStorage.setItem('cartid', cart.id)
          this.setState({
            cartid: cart.id,
            cart: cart
          })
          return cart
        })
    }

    this.update = () => {
      return updateCart(this.state.cartid, this.state.cart)
        .then(cart => this.transformCart(cart))
        .then(cart => {
          this.setState({
            cart: cart
          })
          return cart
        })
    }

    this.addItem = (product_id, quantity, amount, message) => {
      let { cart } = this.state

      if (!cart) {
        return this.create()
          .then((cart) => {
            addToCart(cart.id, product_id, quantity, amount, message)
              .then((cart) => this.transformCart(cart))
              .then((cart) => this.setState({ cart })) // @TODO do we not return cart object here ?
          })
      } else {
        return addToCart(cart.id, product_id, quantity, amount, message)
          .then((cart) => this.transformCart(cart))
          .then((cart) => this.setState({ cart })) // @TODO do we not return cart object here ?
      }
    }

    this.removeItem = (item_id) => {
      let { cart } = this.state

      deleteFromCart(cart.id, item_id)
        .then((cart) => this.transformCart(cart))
        .then((cart) => this.setState({ cart })) // @TODO do we not return cart object here ?
    }

    this.initiateOrder = () => {
      const { cartid } = this.state

      return initiateOrder(cartid)
        .then((result) => {
          localStorage.setItem('transactionid', cartid)
          return result
        })
    }

    this.refresh = () => {
      const { cartid } = this.state

      return getCart(cartid)
        .then(cart => {
          if (!cart) {
            localStorage.removeItem('cartid')
            this.setState({
              cart: null,
              cartid: null,
            })
          }
        })
    }

    this.transformCart = (cart) => {
      if (cart) {
        cart.total = 0
        cart.requires = {
          address: false
        }
        cart.delivery = {
          active: false,
          cost: 5,
          title: 'Leveringskosten',
        }

        cart.items.map((item) => {
          if (item.product.requires.address) {
            cart.requires.address = true
            if (!cart.delivery.active && (cart.use_same_address === SAME_ADDRESS || cart.use_same_address === DIFF_ADDRESS)) {
              cart.total += parseInt(cart.delivery.cost)
              cart.delivery.active = true
            }
          }
          cart.total += parseInt(item.amount)
          return item
        })

        cart.requiresAddress = () => {
          return cart.requires.address
        }
      }

      return cart
    }

    this.getTransaction = (transactionid) => {
      return getTransaction(transactionid) // @TODO protect with cart id
        .then((order) => {
          if (order && order.cartid === this.state.cartid) {
            this.setState({ cart: null, cartid: null })
          }

          return order
        })
    }

    this.clearCart = () => {
      this.setState({ cart: null, cartid: null }, () => {
        localStorage.removeItem('cartid')
      })
    }

    const cartID = (typeof localStorage !== 'undefined') ? localStorage.getItem('cartid') : null
    const transactionID = (typeof localStorage !== 'undefined') ? localStorage.getItem('transactionid') : null

    let cart = null
    let loaded = false

    if (cartID) {
      this.get(cartID)
        .then((cart) => this.transformCart(cart))
        .then(cart => {
          if (cart) {
            this.setState({ cart, cartid: cart.id, loaded: true, transactionid: transactionID })
          }
          else {
            localStorage.removeItem('cartid')
            this.setState({ loaded: true, transactionid: transactionID })
          }
        })
    } else {
      loaded = true
    }

    this.state = {
      cartid: null,
      transactionid: null,
      cart: cart,
      get: this.get,
      set: this.set,
      update: this.update,
      addItem: this.addItem,
      removeItem: this.removeItem,
      initiateOrder: this.initiateOrder,
      refresh: this.refresh,
      getTransaction: this.getTransaction,
      clearCart: this.clearCart,
      loaded: loaded
    }
  }

  render() {
    return (
      <CartContext.Provider value={ this.state }>
        {this.state.loaded ? this.props.children : ''}
      </CartContext.Provider>
    )
  }
}

export default CartProvider
